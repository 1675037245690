
import axios from '@/common/js/request'
// 获取维修列表
export function inspectionPlanList(params){ // 巡检计划分页列表
    const inspectionPlanList = axios({
        url: '/zypatrol/plan/config/page',
        method: 'get',
        params
    })
    return inspectionPlanList
}

export function setStatus(data){ // 设置巡检计划状态
    const setStatus = axios({
        url: '/zypatrol/plan/config/edit',
        method: 'post',
        data
    })
    return setStatus
}
//zypatrol/task/config/list
export function delset(data){ // 设置巡检计划状态
    const delset = axios({
        url: '/zypatrol/plan/config/del',
        method: 'post',
        data
    })
    return delset
}
export function taskLine(params){ // 设置巡检计划状态
    const taskLine = axios({
        url: '/zypatrol/task/config/list',
        method: 'get',
        params
    })
    return taskLine
}
// patrol/plan/config/update
// 计划安排-添加-编辑计划
export function ChangePlan(data){ // 设置巡检计划状态
    const ChangePlan = axios({
        url: '/zypatrol/network/update',
        method: 'post',
        data
    })
    return ChangePlan
}
//zypatrol/plan/config/get
// 巡线计划操作
export function operation(data){ 
    const ChangePlan = axios({
        url: '/zypatrol/plan/config/operation',
        method: 'post',
        data
    })
    return ChangePlan
}
export function GetPlanDetail(params){
    const GetPlanDetail = axios({
        url: '/zypatrol/plan/config/get',
        method: 'get',
        params
    })
    return GetPlanDetail
}
// /zypatrol/plan/config/getEdit
export function getEditConfig(params){
    const getEditConfig = axios({
        url: '/zypatrol/plan/config/getEdit',
        method: 'get',
        params
    })
    return getEditConfig
}
export function getNetwork(params){
    const getNetwork = axios({
        url: '/zypatrol/getNetwork',
        method: 'get',
        params
    })
    return getNetwork
}
export function findTreeMap(params){
    const findTree = axios({
        url: '/layer/findTree',
        method: 'get',
        params
    })
    return findTree
}
// 计划安排-添加-编辑计划
export function projectAdd(data){ 
    const projectAdd = axios({
        url: '/zypatrol/plan/config/update',
        method: 'post',
        data
    })
    return projectAdd
}
// 排序-图层
export function coverageSort(data){ 
    const coverageSort = axios({
        url: '/layer/sort',
        method: 'post',
        data
    })
    return coverageSort
}
// 新增-图层
export function coverageAdd(data){ 
    const coverageAdd = axios({
        url: '/layer/save',
        method: 'post',
        data
    })
    return coverageAdd
}
// 修改-图层
export function coverageupdate(data){ 
    const coverageupdate = axios({
        url: '/layer/update',
        method: 'post',
        data
    })
    return coverageupdate
}
// 更新片区
export function networkUpdate(params){ 
    const networkUpdate = axios({
        url: '/zypatrol/network/networkUpdate',
        method: 'get',
        params
    })
    return networkUpdate
}