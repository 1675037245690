<template>
  <div class="contain">
    <!-- <div style="display: flex"> -->
      <!-- <span style="margin-top:4px">时间范围：</span> -->
      
      <div class="modalityTop">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs">
        <el-breadcrumb-item>巡线系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>巡检管理</el-breadcrumb-item>
        <el-breadcrumb-item><span>计划安排</span></el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <modality /> -->
      <el-form
        ref="search"
        class="searchForm"
        :model="search"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus">
          <!-- @change="searchFun" -->
          <el-date-picker
            
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="任务情况" prop="equipmentStatus" style="margin-left:50px;">
          <el-select
            clearable
            style="width:170px;"
            v-model="search.isPlan"
            placeholder="请选择任务情况"
            
          >
          <!-- @change="inquire" -->
            <el-option
              v-for="item in mission"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="equipmentStatus">
          <el-select
            clearable
            style="width:170px"
            v-model="search.isOpen"
            placeholder="请选择状态"
            
          >
          <!-- @change="inquire" -->
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
            placeholder="请输入计划编码/执行人姓名/巡检片区"
            v-model="search.planName"
            style="width:340px"
          >
          </el-input>
        </el-form-item>
       
        <el-button
          size="mini"
          type="primary"
          @click="searchFun"
          style="margin-left: 50px;width:80px;height:40px"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          style="margin-left: 10px;width:80px;height:40px"
          @click="reset"
          >重置</el-button
        >
      </el-form>
    </div>
    <!-- </div> -->
    <!-- <div class="top-bar">
      <div class="left"> -->
        <!-- <el-button-group>
          <el-button size="mini" icon="el-icon-delete" @click="changeStatus(3)">删除</el-button>
         <el-button size="mini" icon="el-icon-circle-close" @click="changeStatus(2)">关闭</el-button>
         <el-button size="mini" icon="el-icon-refresh" @click="changeStatus(1)">恢复</el-button>
         <el-button size="mini" icon="el-icon-check" @click="changeStatus(1)">执行</el-button>
        </el-button-group> -->
      <!-- </div>
    </div> -->
    <div class="modalityCenter">
     <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="goPath('/inspePlanAdd')"
          >添加</el-button
        >
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      max-height="550"
      :cell-style="{textAlign:'center'}"
      :header-cell-style="{
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 900,
        background: '#DCDFE8',
      }"
    >
    <el-table-column
      type="index"
      label="序号">
    </el-table-column>
    <el-table-column align="center" prop="networkName" label="巡检区域">
      <template slot-scope="scope" @click="goPath('/inspePlanAdd',row.id,true)">
          {{ scope.row.networkName }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="executorPeopleName" label="巡线员">
      </el-table-column>
      
      <el-table-column align="center" prop="lineLength" label="管线长度（km）">
        <template slot-scope="scope">
          {{ (scope.row.lineLength / 1000).toFixed(2) || 0}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="equipmentCount" label="巡检点个数">
      </el-table-column>
      <el-table-column align="center" prop="checkingPoint" label="打卡点个数">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间">
      </el-table-column>
      <el-table-column align="center" prop="isPlan" label="任务情况">
      </el-table-column>
      <el-table-column align="center" prop="isOpen" label="状态">
      </el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="{ row }">
          <!-- <el-button
            @click.native.prevent="changeStatus(1, row.id)"
            type="text"
            size="small"
            v-if="row.planStatus == 1"
            >发布</el-button
          >
          <el-button
            @click.native.prevent="changeStatus(1, row.id)"
            type="text"
            size="small"
            v-if="row.planStatus == 0"
            >重新发布</el-button
          > -->
          <el-button type="text" size="small" @click="goPath('/inspePlanAdd',row.id)">编辑</el-button>
          <el-button
            @click.native.prevent="clickStatus(2, row)"
            type="text"
            size="small"
            v-if="row.isOpen == '已开启'"
            style="color:#FF5400"
            >关闭</el-button
          >
          <el-button
            @click.native.prevent="clickStatus(1, row)"
            type="text"
            size="small"
            v-if="row.isOpen == '已关闭'"
            >开启</el-button
          >
          <el-button
            @click.native.prevent="changeStatus(3, row.id)"
            type="text"
            style="color:#FF5400"
            size="small">删除</el-button >
          <!--          <el-button type="text" size="small" @click="DataDetailFun(row)">查看</el-button>-->
          
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    </div>
    <!--    <el-dialog-->
    <!--            :visible.sync="addVisible"-->
    <!--            @closed="CloseChangePlan"-->
    <!--    >-->
    <!--      <el-form style="width:100%" ref="form" label-width="80px">-->
    <!--        <el-form-item label="计划名称">-->
    <!--          <el-input style="width:100%" v-model="DeviceData.planName"></el-input>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="任务列表">-->
    <!--          <el-select multiple style="width:100%" v-model="DeviceData.planTaskConfigIds">-->
    <!--            <el-option v-for="item in patrolArray"-->
    <!--                       :value="item.id"-->
    <!--                       :label="'任务执行人：'+item.executorPeopleName +'巡检编号：'+item.taskConfigCode"-->
    <!--                       :key="item.id"-->
    <!--            >-->
    <!--            </el-option>-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="执行类型">-->
    <!--          <el-radio-group v-model="DeviceData.planType">-->
    <!--            <el-radio-button label="one">执行一次</el-radio-button>-->
    <!--            <el-radio-button label="unone">重复执行</el-radio-button>-->
    <!--          </el-radio-group>-->
    <!--        </el-form-item>-->
    <!--        <div v-if="DeviceData.planType == 'one'">-->
    <!--          <el-form-item  label="执行时间">-->
    <!--            <el-date-picker-->
    <!--                    value-format="yyyy-MM-dd HH:mm:ss"-->
    <!--                    v-model="ExecutionTime"-->
    <!--                    type="datetime"-->
    <!--                    placeholder="选择日期时间"-->
    <!--            >-->
    <!--            </el-date-picker>-->
    <!--          </el-form-item>-->
    <!--        </div>-->
    <!--        <div v-if="DeviceData.planType == 'unone'">-->
    <!--&lt;!&ndash;          <el-form-item label="执行频率">&ndash;&gt;-->
    <!--&lt;!&ndash;            <el-radio-group v-model="DeviceData.planFrequency" size="medium">&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-radio-button label="day">每天</el-radio-button>&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-radio-button label="week">每周</el-radio-button>&ndash;&gt;-->
    <!--&lt;!&ndash;              <el-radio-button label="month">每月</el-radio-button>&ndash;&gt;-->
    <!--&lt;!&ndash;            </el-radio-group>&ndash;&gt;-->
    <!--&lt;!&ndash;          </el-form-item>&ndash;&gt;-->
    <!--          <el-form-item v-if="DeviceData.planFrequency == 'week'">-->
    <!--            <el-checkbox-group v-model="WeekSelectArray">-->
    <!--              <el-checkbox-->
    <!--                  v-for="(item) in WeekArray"-->
    <!--                  :label="item.value"-->
    <!--                  :key="item.value">-->
    <!--                {{item.name}}-->
    <!--              </el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--          </el-form-item>-->
    <!--          <el-form-item label="" v-if="DeviceData.planFrequency == 'month'">-->
    <!--            <el-checkbox-group v-model="MountSelectArray">-->
    <!--              <el-checkbox-->
    <!--                  v-for="(item) in monthArray"-->
    <!--                  :label="item"-->
    <!--                  :key="item"-->
    <!--              >-->
    <!--                {{item}}-->
    <!--              </el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--          </el-form-item>-->
    <!--          <el-form-item label="每天频率">-->
    <!--            <el-input type="number" v-model="DeviceData.planFrequency"></el-input>-->
    <!--&lt;!&ndash;            <el-time-picker&ndash;&gt;-->
    <!--&lt;!&ndash;                    value-format="HH:mm:ss"&ndash;&gt;-->
    <!--&lt;!&ndash;                    v-model="DeviceData.planDurationDailyTime"&ndash;&gt;-->
    <!--&lt;!&ndash;                    placeholder="任意时间点">&ndash;&gt;-->
    <!--&lt;!&ndash;            </el-time-picker>&ndash;&gt;-->
    <!--          </el-form-item>-->
    <!--          <el-form-item label="持续日期">-->
    <!--            <el-date-picker-->
    <!--                    value-format="yyyy-MM-dd"-->
    <!--                    v-model="StartAndEndTime"-->
    <!--                    type="daterange"-->
    <!--                    range-separator="至"-->
    <!--                    start-placeholder="开始日期"-->
    <!--                    end-placeholder="结束日期">-->
    <!--            </el-date-picker>-->
    <!--          </el-form-item>-->
    <!--        </div>-->
    <!--        <el-form-item label="说明">-->
    <!--          <el-input v-model="DeviceData.planInstructions" type="textarea">-->

    <!--          </el-input>-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--      <div style="text-align: center">-->
    <!--        <el-button @click="submitPatrol">提交</el-button>-->
    <!--        <el-button @click="CancelSubmit">取消</el-button>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
    <!-- <el-dialog :visible.sync="DataDetaliBool">
      <table class="view-table">
        <tr>
          <th>计划名称</th>
          <td>{{ DataDetail.planName }}</td>
          <th>持续时间</th>
          <td>
            {{ DataDetail.planDurationStartTime }} 至
            {{ DataDetail.planDurationEndTime }}
          </td>
        </tr>
        <tr>
          <th>计划状态</th>
          <td>{{ DataDetail.planStatusName }}</td>
          <th>完成率</th>
          <td>{{ DataDetail.completeRate }}</td>
        </tr>
        <tr>
          <th>巡检点总数</th>
          <td>{{ DataDetail.taskNumber }}</td>
          <th>完成数</th>
          <td>{{ DataDetail.taskCompleteNumber }}</td>
        </tr>
        <tr>
          <th>下发说明</th>
          <td colspan="3">{{ DataDetail.planInstructions }}</td>
        </tr>
      </table>
    </el-dialog> -->

    <!-- <el-dialog
      title="巡检计划"
      width="30%"
      :close-on-click-modal="false"
      :visible.sync="addVisible"
      @close="closeDialog('form')"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rule">
        <el-form-item label="计划执行人：" prop="executorPeopleId">
          <el-select
            clearable
            style="width: 100%"
            value-key="userId"
            v-model="form.executorPeopleId"
            placeholder="计划执行人"
          >
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="String(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检片区:" prop="networkId">
          <el-select
            class="point"
            style="width: 100%"
            clearable
            collapse-tags
            v-model="form.networkId"
            placeholder="巡检点"
          >
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
              :label="item.network_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button @click="submitPatrol" type="primary">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  inspectionPlanList,
  setStatus,
  taskLine,
  ChangePlan,
  GetPlanDetail,
  getEditConfig,
  delset,
  operation
} from "../../RequestPort/Inspection/inspePlan.js";
import {
  checkpointlist,
  getNetwork,
  getUserList,
} from "../../RequestPort/maintenance/task";
import { ByPostpeople } from "@/RequestPort/maintenance";
// import modality from "@/components/modality";
export default {
  name: "inspePlan",
  // components: { modality },
  data() {
    return {
      // WeekArray: [
      //   { name: "星期一", value: 1 },
      //   { name: "星期二", value: 2 },
      //   { name: "星期三", value: 3 },
      //   { name: "星期四", value: 4 },
      //   { name: "星期五", value: 5 },
      //   { name: "星期六", value: 6 },
      //   { name: "星期日", value: 7 },
      // ],
      // 任务情况
      mission:[
        { label: "未安排", value: 2 },
        { label: "已安排", value: 1 },
      ],
      // 状态
      stateList:[
        { label: "已开启", value: 1 },
        { label: "已关闭", value: 2 },
      ],
      // monthArray: [],
      DataPicker: [],
      search: {
        current: 1,
        planName: "",
        size: 15,
      },
      // status: "",
      // 列表数据
      tableData: [],
      // searchText: "",
      // 总条数
      total: 0,
      choseArr: [],
      // 编辑弹窗
      // addVisible: false,
      // DeviceData: {
      //   planDurationDailyTime: "",
      //   planDurationEndTime: "",
      //   planDurationStartTime: "",
      //   planDurationType: 0,
      //   planExecuteOnceDate: "",
      //   planFrequency: "day",
      //   planInstructions: "",
      //   planMonthDay: "",
      //   planName: "",
      //   planTaskConfigIds: [],
      //   planType: "one",
      //   planWeek: "",
      // },
      // ExecutionTime: "",
      // ChosePlanType: "1",
      // patrolArray: [],
      // StartAndEndTime: [],
      // WeekSelectArray: [],
      // MountSelectArray: [],
      // ChangeBool: false,
      // DataDetaliBool: false,
      // DataDetail: {},
      // selectDownArray: [],
      // multipleSelection: [],
      // excutorDownArray: [],
      form: {
        executorPeopleId: "",
        id: "",
        networkId: "",
      },
      // rule: {
      //   executorPeopleId: [
      //     { required: true, message: "请选择", trigger: "change" },
      //   ],
      //   networkId: [{ required: true, message: "请选择", trigger: "change" }],
      // },
      // executor: {},
    };
  },
  mounted() {
    // getNetwork().then((e) => {
    //   this.selectDownArray = e.data;
    // });
    // getUserList().then((e) => {
    //   this.excutorDownArray = e.data;
    // });
    // checkpointlist().then((res) => {
    //   this.pointList = res.data;
    // });
    this.getList();
    // let monthData = 32
    // for(var i=1;i<monthData;i++){
    //   this.monthArray.push(i)
    // }
    // taskLine().then((e)=>{
    //   this.patrolArray = e.data
    // })
  },
  methods: {
    /**
     * 触发下拉进行查询
     */
    inquire(){
      this.getList()
    },
    /**
     * 编辑开启和关闭状态
     */
    clickStatus(val,row){
      let p = {
        id:row.id,
        isOpen:val
      }
      operation(p).then((e) => {
        this.getList();
        this.$message.success("操作成功");
      });
    },
    // closeDialog(form) {
    //   this.$refs[form].resetFields();
    //   this.form = {
    //     executorPeopleId: "",
    //     id: "",
    //     networkId: "",
    //   };
    //   this.executor = {};
    //   this.cooperate = {};
    // },
    // submit() {
    //   if (this.executor.userId) {
    //     if (
    //       this.form.checkpointList.length < 1 &&
    //       this.form.pipelineList.length < 1
    //     ) {
    //       return this.$message.error("请选择巡检点或管线");
    //     }
    //     (this.form.cooperatePeopleId = this.cooperate.userId),
    //       (this.form.cooperatePeopleName = this.cooperate.userName),
    //       (this.form.executorPeopleId = this.executor.userId),
    //       (this.form.executorPeopleName = this.executor.userName),
    //       updatetask(this.form).then((res) => {
    //         if (res.code === 200) {
    //           this.addVisible = !this.addVisible;
    //           this.loadlist(this.paramsData);
    //           this.$message.success(res.msg);
    //           this.selectpointList = [];
    //           this.selectline = [];
    //           this.executor = {};
    //           this.cooperate = {};
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //       });
    //   } else {
    //     return this.$message.error("未选择执行人");
    //   }
    // },
    /**
     * 查询事件
     */
    searchFun() {
      this.search.current = 1;
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.search.startDate = this.DataPicker[0];
        this.search.endDate = this.DataPicker[1];
      } else {
        this.search.startDate = null;
        this.search.endDate = null;
      }
      this.getList();
    },
    /**
     * 重置
     */
    reset() {
      this.search = {
        current: 1,
        planName: "",
        size: 15,
      };
      this.DataPicker = [];
      this.getList();
    },
    // DataDetailFun(e) {
    //   this.DataDetaliBool = !this.DataDetaliBool;
    //   GetPlanDetail({ id: e.id }).then((e) => {
    //     this.DataDetail = e.data;
    //     if (this.DataDetail.planStatus == 0) {
    //       this.DataDetail.planStatusName = "关闭";
    //     } else if (this.DataDetail.planStatus == 1) {
    //       this.DataDetail.planStatusName = "未发布";
    //     } else {
    //       this.DataDetail.planStatusName = "已发布";
    //     }
    //   });
    // },
    // CloseChangePlan() {
      // this.DeviceData = {
      //   planDurationDailyTime: "",
      //   planDurationEndTime: "",
      //   planDurationStartTime: "",
      //   planDurationType: 0,
      //   planExecuteOnceDate: "",
      //   planFrequency: "day",
      //   planInstructions: "",
      //   planMonthDay: "",
      //   planName: "",
      //   planTaskConfigIds: [],
      //   planType: "one",
      //   planWeek: "",
      // };
      // this.StartAndEndTime = [];
      // this.WeekSelectArray = [];
      // this.MountSelectArray = [];
    // },
    /**
     * 编辑按钮，打开弹窗
     */
    // ChangeDataFun(e) {
    //   this.form = JSON.parse(JSON.stringify(e));
    //   this.addVisible = !this.addVisible;
    //   // getEditConfig({id:e.id}).then((e)=>{
    //   //   this.DeviceData = e.data
    //   // })
    // },
    // CancelSubmit() {
    //   this.addVisible = !this.addVisible;
    // },
    /**
     * 编辑提交（掉接口）
     */
    // submitPatrol() {
      // if(this.DeviceData.planType == ''){
      //   this.$message.error('请选择执行类型')
      //   return
      // }
      // if(this.ExecutionTime != ''){
      //   this.DeviceData.planExecuteOnceDate = this.ExecutionTime.split(' ')[0]
      //   this.DeviceData.planDurationDailyTime = this.ExecutionTime.split(' ')[1]
      // }
      // if(this.StartAndEndTime.length>0){
      //   this.DeviceData.planDurationStartTime = this.StartAndEndTime[0]
      //   this.DeviceData.planDurationEndTime = this.StartAndEndTime[1]
      // }
      // if(this.WeekSelectArray.length>0){
      //   let stringArray = ''
      //   this.WeekSelectArray.forEach((item,index)=>{
      //     stringArray+=item+','
      //   })
      //   this.DeviceData.planWeek = stringArray
      // }
      // if(this.MountSelectArray.length>0){
      //   let MounthString = ''
      //   this.MountSelectArray.forEach((item,index)=>{
      //     MounthString+=item+','
      //   })
      //   this.DeviceData.planMonthDay = MounthString
      // }
    //   this.$refs.form.validate((valid) => {
    //     if (valid) {
    //       ChangePlan(this.form).then((e) => {
    //         this.getList();
    //         this.$message.success("操作成功");
    //         this.addVisible = !this.addVisible;
    //       });
    //     }
    //   });
    // },
    /**
     * 添加计划//跳转
     */
    goPath(path, id,strat) {
      this.$router.push({ path: path, query: { id: id, inquire:strat} })
    },
    /**
     * 删除
     */
    changeStatus(type, id) {
      if (id) {
        this.$confirm("此操作将永久删除这条数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal:false,
          type: "warning",
        }).then(() => {
          delset([id]).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
        return;
      }
      // let data;
      // 删除列表
      // if (type == 3) {
      //   if (this.choseArr.length > 0) {
      //     this.$confirm("此操作将永久删除这条数据, 是否继续?", "提示", {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     })
      //       .then(() => {
      //         let ids = [];
      //         this.choseArr.forEach((item) => {
      //           ids.push(item.id);
      //         });
      //         delset(ids).then((res) => {
      //           if (res.code === 200) {
      //             this.$message.success(res.msg);
      //             this.getList();
      //             this.$message({
      //               type: "success",
      //               message: "删除成功!",
      //             });
      //           }
      //         });
      //       })
      //       .catch(() => {
      //         this.$message({
      //           type: "info",
      //           message: "已取消删除",
      //         });
      //       });
      //     return;
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: "未选择删除项目",
      //     });
      //   }
      // }
      // if (typeof id == "number") {
      //   data = {
      //     editType: type,
      //     ids: [id],
      //   };
      // } else {
      //   let ids = [];
      //   this.choseArr.forEach((item) => {
      //     ids.push(item.id);
      //   });
      //   data = {
      //     editType: type,
      //     ids,
      //   };
      // }
      // setStatus(data).then((res) => {
      //   if (res.code === 200) {
      //     this.$message.success(res.msg);
      //     this.getList();
      //   }
      // });
    },
    /**
     * 查询列表数据
     */
    getList() {
      inspectionPlanList(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handleSizeChange(e) {
      this.search.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getList();
    },
    handleSelectionChange(e) {
      this.choseArr = e;
    },
  },
};
</script>

<style lang="less" scoped>
.contain{
  background-color:transparent;
}
// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  .crumbs{
    padding: 10px 15px 20px;
    span{
      color: #3069E1;
    }
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
}
// 搜索
.searchForm{
  display: flex;
  flex-wrap: wrap;
    justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 分页按钮
.el-pagination{
      margin: 15px 0 0px;
}
.view-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.view-table th {
  // background-color: #f5faff;
  font-weight: 400;
  text-align: left;
  width: 180px;
}
.view-table td,
.view-table th {
  border: 1px solid #d1e8fe;
  line-height: 40px;
  padding: 0 15px;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  .left {
    width: 30%;
    margin: 10px 0;
  }
  .right {
    width: 35%;
  }
}
.el-input-group,
.el-input {
  width: initial;
}
.reset {
  margin-left: 10px;
}
.block {
  text-align: right;
}
</style>
